
































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import WheelChangeData from '@/components/tyre-hotel/WheelChangeData.vue'
import WheelUtils from '@/components/tyre-hotel/tyre-wheel-data/WheelUtils.vue'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import WheelSetPositionSelector from '@/components/tyre-hotel/WheelSetPositionSelector.vue'
import NewOrEditImageDialog from '@/components/tyre-hotel/image-data/NewOrEditImageDialog.vue'
import FullImageDialog from '@/components/tyre-hotel/image-data/FullImageDialog.vue'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {
    ListView,
    FullImageDialog,
    NewOrEditImageDialog,
    DocumentDialog,
    WheelChangeData,
    WheelSetPositionSelector,
  },
  mixins: [WheelUtils],
})
export default class TyreWheelDesktop extends Vue {
  public $refs: Vue['$refs'] & {
    focus: any
  }

  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  @Prop({ type: Array, required: true })
  private locations: Array<any>

  private document = null
  private documentDialogVisible = false

  private changePositionsDialog = 0

  private searchDimension = this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels[0].dimension

  private searchDimensionOrderTypes = [
    {
      value: 'order',
      text: this.$t('c:tyre-hotel:Order'),
    },
    {
      value: 'offer',
      text: this.$t('c:tyre-hotel:Offer'),
    },
  ]

  private searchDimensionOrderType = 'order'

  created() {
    this.searchDimensionOrderType =
      localStorage.getItem('tyreHotelSearchDimensionOrderType') || this.searchDimensionOrderType
    localStorage.setItem('tyreHotelSearchDimensionOrderType', this.searchDimensionOrderType)
  }

  private getDotAndAirPressureText(item) {
    const result = []
    if (item.dotMark) {
      result.push(item.dotMark)
    }
    if (item.airPressure) {
      result.push(item.airPressure + ' bar')
    }
    return result.join(', ')
  }

  protected moveFocusToNext(currentWheelIndex: number, inputType: string): void {
    let nextWheelIndex: number
    if (inputType === 'treadDepth') {
      nextWheelIndex = currentWheelIndex + 1
    } else if (inputType === 'airPressure') {
      nextWheelIndex = currentWheelIndex + 1
    }

    if (nextWheelIndex >= this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels.length) {
      nextWheelIndex = 0
    }
    const nextInputRef =
      inputType === 'treadDepth' ? 'treadDepthInput' + nextWheelIndex : 'pressureInput' + nextWheelIndex

    const nextInputElement = this.$refs[nextInputRef]

    if (nextInputElement instanceof HTMLElement) {
      nextInputElement.focus()
    }
  }

  private openLabels(): void {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelWheelSetLabels'
    this.document.params = {
      tyreHotelWheelSetId: this.tyreHotel.activeTyreHotelWheelSet.id,
    }
    this.documentDialogVisible = true
  }

  private openPileLabel(): void {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelWheelSetPileLabel'
    this.document.params = {
      tyreHotelWheelSetId: this.tyreHotel.activeTyreHotelWheelSet.id,
    }
    this.documentDialogVisible = true
  }

  private changePositions(): void {
    this.changePositionsDialog = new Date().getTime()
  }

  private onSavedPositions(update): void {
    this.changePositionsDialog = 0
    const wheelSet = this.tyreHotel.getTyreHotelWheelById(update.wheelSetId)
    if (!wheelSet) {
      return
    }
    const oldWheels = wheelSet.tyreHotelWheels
    const newWheelsWithPositions = update.positions
    for (let i = 0; i < newWheelsWithPositions.length; i++) {
      const n = newWheelsWithPositions[i]
      for (let j = 0; j < oldWheels.length; j++) {
        const o = oldWheels[j]
        if (o.id === n.wheelId) {
          o.positionSummary = n.positionSummary
        }
      }
    }
    this.tyreHotel.storageLocationId = wheelSet.tyreHotelWheels[0].positionSummary.locationId
  }

  private onComponentChanged(): void {
    this.$emit('onComponentChanged')
  }

  private searchWithDimension() {
    const loadIndex = this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels[0].loadIndex
    const speedIndex = this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels[0].speedIndex
    const search = this.searchDimension.replace('/', '.').replace('-', '.')

    localStorage.setItem('tyreHotelSearchDimensionOrderType', this.searchDimensionOrderType)

    let searchQuery = `${search}`
    if (loadIndex && speedIndex) {
      searchQuery += ` ${loadIndex}${speedIndex}`
    } else if (loadIndex) {
      searchQuery += ` ${loadIndex}`
    } else if (speedIndex) {
      searchQuery += ` ${speedIndex}`
    }

    this.$axios.get('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/last-active-wheel-change').then((response) => {
      const responseData = response.data.data
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (responseData !== undefined && responseData.orderId !== null) {
        window.open(
          appendSitePrefix(
            `/${responseData.orderId}/order2/main/null?search=${searchQuery}%20${responseData.season}`,
          ),
        )
      } else {
        const data = {
          receiveTyreHotelWheelSetId: this.tyreHotel.getTyreHotelWheelSetOnCar()?.id,
          returnTyreHotelWheelSetId: this.defaultReturnWheelSetId,
          orderIsOffer: this.searchDimensionOrderType === 'offer',
        }
        this.$axios
          .post('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/open-wheel-change', data)
          .then((response) => {
            const responseData = response.data.data
            window.open(
              appendSitePrefix(
                `/${responseData.orderId}/order2/main/null?search=${searchQuery}%20${responseData.season}`,
              ),
            )
          })
      }
    })
  }

  private get returnTyreHotelWheelSets() {
    return this.tyreHotel.getTyreHotelWheelSetsInHotel()
  }

  private get defaultReturnWheelSetId() {
    if (this.returnTyreHotelWheelSets.length === 1) {
      return this.returnTyreHotelWheelSets[0].id
    }
    return null
  }

  @Watch('isSaving')
  private onFirstTyreDimensionChanged() {
    this.searchDimension = this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels[0].dimension
  }

  @Watch('tyreHotel.lastTyreHotelWheelChange.order')
  private onTyreHotelWheelChangeOrderInitiated() {
    if (this.tyreHotel.lastTyreHotelWheelChange?.orderId) {
      this.searchDimensionOrderTypes = []
      const isOffer = this.tyreHotel.lastTyreHotelWheelChange.order.isOffer
      const orderId = this.tyreHotel.lastTyreHotelWheelChange.orderId

      if (isOffer === undefined || isOffer === null) {
        this.searchDimensionOrderTypes = [
          {
            value: 'order',
            text: this.$t('c:tyre-hotel:Order') + ' #' + orderId,
          },
        ]
        this.searchDimensionOrderType = 'order'
      } else {
        this.searchDimensionOrderTypes = [
          {
            value: 'offer',
            text: this.$t('c:tyre-hotel:Offer') + ' #' + orderId,
          },
        ]
        this.searchDimensionOrderType = 'offer'
      }
    }
  }
}
